<template>
    <article id="wrap">
      <header>
        <ul class="page_head head_gray">
          <li class="menuL"><router-link :to="{name: 'mypage'}">
            <img src="@/assets/img/icon_back.png" alt="">
          </router-link></li>
          <li class="menuC"><h1 class="page_head_h1"></h1></li>
          <li class="menuR"></li>
        </ul>
      </header>
  
      <section class="contents">
        <div class="contentInner pb_80">
          <ParticipantsList :artists="artists" mode="selector"></ParticipantsList>
        </div><!--.contentInner-->
      </section><!--.contents-->
  
      <AppFooter theme="yellow" fixed></AppFooter>
  
    </article>
  </template>

<script>
import AppFooter from '@/components/AppFooter';
import ParticipantsList from '@/components/ParticipantsList';

export default {
  name: 'ArtistParticipantsList',
  components: { 
    ParticipantsList,
    AppFooter, 

  },
  data() {
    return {
        artists: [],
        paymentPlanId: null
      };
  },
  created () {
    console.log(this.$route.params.paymentPlanId)
    this.paymentPlanId = this.$route.params.paymentPlanId;
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await this.$http.post('artists/talk_room_user/' + this.$store.state.loginUser.artist_id + '/' + this.paymentPlanId);
      // const url = 'http://127.0.0.1:8000/api/artists/talk_room_user/' + this.$store.state.loginUser.artist_id + '/' + this.paymentPlanId;
      // const response = await this.$http.post(url);
      console.log(response)
      this.artists = response.data;
    }
  }
}
</script>
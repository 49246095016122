<template>
    <div>
        {{ artist.talk_room_users }}
        <ul class="myartist_area" >
          <li>
            <div class="myartist_photo">
              <img src="@/assets/img/signup/add_photo2.png" alt="商品画像" v-if="!artist.image">
              <img :src="`${rootUrl}${artist.image}`" alt="ユーザー画像">
            </div>
          </li>
          <li>
            <!-- <div class="bold talk_room_name">{{ artist.nick }}</div>
            <div class="artist_date" v-for="room in artist.talk_rooms" :key="room.id">
              加入トークルーム名：
              <span>{{ room.name }}</span>
              <span>----</span>
            </div> -->
            <div class="bold talk_room_name">{{ artist.nick }}</div>  
            <div class="artist_info">誕生日：
              <span>{{ artist.birthday }}</span>
            </div>
            <!-- <div class="artist_info">コメント：
                <span>{{ artist.comment }}</span>
                <span>----</span>
            </div> -->
          </li>             
        </ul>

    </div>
  </template>

<script>
export default {

  props: {
    artist: Object,
    mode: {
      validator: function (value) {
        return ['holder', 'selector'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    rootUrl () {
      return `${process.env.VUE_APP_API_ROOT}../`;
    },
  }
}

</script>


<style lang="scss" scoped>
ul.area_link {
  cursor: pointer;
}
ul.myartist_area {
  background-color: #fff;
  // border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 0.5px gray;
  border-top: solid 0.5px gray;
}
ul.myartist_area li .myartist_photo {
  width: 85px;
  height: 100%;
  min-height: 85px;
  overflow: hidden;
}
ul.myartist_area li .myartist_photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
ul.myartist_area li:nth-child(2) {
  flex-grow: 1;
  padding: 10px 0 5px 10px;
}
ul.myartist_area li:nth-child(3) {
  align-self: center;
  padding: 10px;
}
#wrap ul.myartist_area li .bt_form a ,
#wrap ul.myartist_area li .bt_delete a {
  font-size: 12rem;
  padding: 8px;
  width: 100px;
}
#wrap ul.myartist_area li .bt_small_text a {
  font-size: 10rem;
  padding: 8px 4px;
}

ul.myartist_area li .artist_date {
  font-size: 8rem;
  color: #929292;
  margin-top: 5px;
}

ul.myartist_area li .artist_info {
  font-size: 8rem;
  color: #929292;
  margin-top: 5px;
}

ul.myartist_area li .artist_info_master {
  color: black;
  font-weight: bold;
}
ul.myartist_area .talk_room_name {
  font-size: 13px;
}
</style>

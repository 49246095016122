<template>
    <div>
        <ParticipantsLink
            v-for="artist in artists"
            :artist="artist"
            :mode="mode"
            :key="`artists_${artist.id}`"
            ></ParticipantsLink>
    </div>
</template>

<script>
import ParticipantsLink from '@/components/ParticipantsLink';

export default {
components: { ParticipantsLink },
props: {
    artists: Array,
    mode: {
      validator: function (value) {
        return ['holder', 'selector'].indexOf(value) !== -1;
      },
    },
    artistId: Number,
  },
}
</script>